import React from 'react'
import { DELETE, PLUS, TICK_ICON } from '../../../icons'
import { groupBy } from '../../../Utils/helper'
import PartyDropdown from '../../../civillocal/ReusableComponents/PartyDropdown';
import ProjectDropdown from '../../../civillocal/ReusableComponents/ProjectDropdown';

const WeeklyEmployee = ({ attendanceData, setAttendanceData }) => {
    let groups = groupBy(attendanceData, 'employeeId');

    console.log('groups', groups)

    function handleChange(uniqueIndex, field, value) {
        setAttendanceData(prev => {
            let newList = structuredClone(prev);
            let index = prev.findIndex(i => i.uniqueIndex == uniqueIndex);
            if (field == 'shiftLength' && (newList[index][field] == value)) {
                newList[index][field] = '';
            } else {
                newList[index][field] = value;
            }
            return newList;
        })
    }
    function handleAddRow(row) {
        setAttendanceData(prev => [...prev, { employeeId: row.employeeId, regNo: row.regNo, name: row.name, permissionHours: 0, overTimeHours: 0, uniqueIndex: `${new Date().getTime()}-${Math.random()}` }])
    }
    function handleDeleteRow(uniqueIndex) {
        setAttendanceData(prev => prev.filter((item) => item.uniqueIndex != uniqueIndex))
    }
    let count = 1;
    return (
        <div
            className="min-h-[300px] overflow-auto"
        >
            <table className="table-auto text-center w-full">
                <thead className="border-2 table-header">
                    <tr className='h-2'>
                        <th
                            className="border-2  top-0 stick-bg w-10"
                        >
                            S. no.
                        </th>
                        <th
                            className="border-2  top-0 stick-bg "
                        >
                            <div>Reg.No</div>
                        </th>
                        <th
                            className="border-2  top-0 stick-bg"
                        >
                            <div>Emp.Name</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Party</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Project</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Full Shift</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Half Shift</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>One Half </div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Leave</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Permission(Hours)</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>OT(Hours)</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            Add
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            Delete
                        </th>
                    </tr>
                </thead>
                <tbody className="border-2">
                    {Object.entries(groups).map(([employee, values]) =>
                        <>
                            {values.map((dataObj, index) =>
                                <tr
                                    className="border-2  text-sm cursor-pointer"
                                >
                                    {(index == 0) &&
                                        <>
                                            <td className='py-1 border-r' rowSpan={values.length}>
                                                {count++}
                                            </td>
                                            <td className='py-1 border-r' rowSpan={values.length} >
                                                {dataObj?.regNo}
                                            </td>
                                            <td className='py-1 border-r' rowSpan={values.length} >
                                                {dataObj?.name}
                                            </td>
                                        </>
                                    }
                                    <td className='py-1' onClick={() => {

                                    }}>
                                        <div className='w-52'>
                                            <PartyDropdown withSearch selected={dataObj.partyId} setSelected={(value) => handleChange(dataObj.uniqueIndex, 'partyId', value)} />
                                        </div>
                                    </td>
                                    <td className='py-1' onClick={() => {

                                    }}>
                                        <div className='w-52'>
                                            <ProjectDropdown withSearch selected={dataObj.projectId}
                                                setSelected={(value) => handleChange(dataObj.uniqueIndex, 'projectId', value)}
                                                clientId={dataObj.partyId} />
                                        </div>
                                    </td>
                                    <td className='py-1' onClick={() => {
                                        handleChange(dataObj.uniqueIndex, 'shiftLength', 'full')
                                    }}>
                                        {(dataObj.shiftLength === 'full') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' onClick={() => {
                                        handleChange(dataObj.uniqueIndex, 'shiftLength', 'half')
                                    }}>
                                        {(dataObj.shiftLength === 'half') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' onClick={() => {
                                        handleChange(dataObj.uniqueIndex, 'shiftLength', 'oneHalf')
                                    }}>
                                        {(dataObj.shiftLength === 'oneHalf') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' onClick={() => {
                                        handleChange(dataObj.uniqueIndex, 'shiftLength', 'leave')
                                    }}>
                                        {(dataObj.shiftLength === 'leave') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' >
                                        <input type="number" min={0} step="0.1" className='text-right' value={dataObj.permissionHours} onChange={(e) => {
                                            handleChange(dataObj.uniqueIndex, 'permissionHours', e.target.value)
                                        }} />
                                    </td>
                                    <td className='py-1' >
                                        <input type="number" min={0} step="0.1" className='text-right border w-10 p-1' value={dataObj.overTimeHours} onChange={(e) => {
                                            handleChange(dataObj.uniqueIndex, 'overTimeHours', e.target.value)
                                        }} />
                                    </td>
                                    <td className='py-1' >
                                        {(index == (values.length - 1))
                                            &&
                                            <button type='button' onClick={() => handleAddRow(dataObj)} className='text-blue-600'>
                                                {PLUS}
                                            </button>
                                        }
                                    </td>
                                    <td className='py-1' >
                                        {(values.length > 1) &&
                                            <button type='button' onClick={() => handleDeleteRow(dataObj.uniqueIndex)} className='text-red-700' >
                                                {DELETE}
                                            </button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default WeeklyEmployee
