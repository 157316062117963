export const EMPLOYEE_API = "employees";
export const CITY_API = "cities";
export const STATE_API = "states";
export const COUNTRY_API = "countries";
export const DEPARTMENT_API = "departments";
export const LOGIN_API = "users/login";
export const COMPANY_API = "companies";
export const BRANCHES_API = "branches";
export const USERS_API = "users";
export const PAGES_API = "pages";
export const PAGES_GROUP_API = "pageGroup";
export const ROLES_API = "roles";
export const SUBSCRIPTION_API = "subscriptions";
export const FIN_YEAR_API = "finYear";
export const EMPLOYEE_CATEGORY_API = "employeeCategories";

export const PARTY_CATEGORY_API = "partyCategories"

export const PARTY_API = "party"
export const PRODUCT_BRAND_API = "productBrand"
export const PRODUCT_CATEGORY_API = "productCategory"
export const PRODUCT_SUB_CATEGORY_API = "productSubCategory"
export const PRODUCT_API = "product"
export const PURCHASE_BILL_API = "purchaseBill"
export const STOCK_API = "stock"
export const SALES_BILL_API = "salesBill"
export const PURCHASE_RETURN_API = "purchaseReturn"
export const SALES_RETURN_API = "salesReturn"
export const UOM_API = "uom"
export const QUOTES_API = "quotes"
export const LEAD_API = "lead"
export const PROJECT_API = "project"
export const INVOICE_API = "invoice"
export const HOLIDAYS_CALENDER_API = "holidays"
export const PROJECTPAYMENT_API = "projectPayment"
export const LOAN_ENTRY_API = "loanEntry"
export const ATTENDANCE_GENERATION_API = "attendanceGeneration"





