import React, { useEffect } from 'react'
import { useGetEmployeeQuery, useUpdateAttendanceMutation } from '../../../redux/services/EmployeeMasterService';
import EmployeeCategoryDropdown from '../../ReusableComponents/EmployeeCategoryDropdown';
import { useState } from 'react';
import { DisabledInput } from '../../../Inputs';
import { getDateFromDateTime, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import { useGetEmployeeCategoryByIdQuery } from '../../../redux/services/EmployeeCategoryMasterService';
import MonthlyEmployee from './MonthlyEmployee';
import WeeklyEmployee from './WeeklyEmployee';

const AttendanceForm = ({ selectedDate }) => {
    const [attendanceData, setAttendanceData] = useState([]);
    const [employeeCategoryId, setEmployeeCategoryId] = useState('');

    const { data: employeeData } = useGetEmployeeQuery({ params: { active: true, employeeCategoryId, date: getDateFromDateTime(new Date(selectedDate)), isGetAttendanceDetails: true } }, { skip: !employeeCategoryId });

    const { data: employeeCategoryData } = useGetEmployeeCategoryByIdQuery(employeeCategoryId, { skip: !employeeCategoryId })

    let salaryType = employeeCategoryData?.data?.salaryType || '';

    const [updateAttendance] = useUpdateAttendanceMutation();

    useEffect(() => {
        if (!employeeData?.data) return
        setAttendanceData((employeeData?.data || []).map((i) => ({ ...i, uniqueIndex: `${new Date().getTime()}-${Math.random()}` })));
    }, [employeeData]);




    let isWeekly = salaryType == "Weekly"

    async function handleSave(e) {
        e.preventDefault();
        if (isWeekly) {
            if (!attendanceData.every(i => i.shiftLength)) {
                window.alert("Shift is Missing...!!!")
            }
        }
        const response = await updateAttendance({ date: selectedDate, attendanceDetails: attendanceData, employeeCategoryId }).unwrap();
        if (response?.statusCode === 0) {
            toast.success("Data Saved Successfully")
        } else {
            toast.error(response?.message)
        }
    }
    return (
        <form className='p-2' onSubmit={handleSave}>
            <div className='grid grid-cols-5 gap-2'>
                <DisabledInput name={'Date:'} value={getDateFromDateTimeToDisplay(selectedDate)} />
                <EmployeeCategoryDropdown selected={employeeCategoryId} setSelected={setEmployeeCategoryId} />
            </div>
            {
                isWeekly
                    ?
                    <WeeklyEmployee attendanceData={attendanceData} setAttendanceData={setAttendanceData} />
                    :
                    <MonthlyEmployee attendanceData={attendanceData} setAttendanceData={setAttendanceData} />
            }
            <div className='flex justify-end '>
                <button type='submit' className='bg-blue-600 text-white p-2 rounded'>
                    Submit
                </button>
            </div>
        </form>
    )
}

export default AttendanceForm
