import React from 'react'
import { useGetEmployeeQuery } from '../../../redux/services/EmployeeMasterService';
import MonthlyEmployee from './MonthlyEmployee';
import WeeklyEmployee from './WeeklyEmployee';
import { DisabledInput } from '../../../Inputs';
import EmployeeCategoryDropdown from '../../../Basic/ReusableComponents/EmployeeCategoryDropdown';

const AttendanceBreakUp = ({ employeeId, attendanceGenerationId, employeeCategoryId, isWeekly }) => {
    const { data: employeeData } = useGetEmployeeQuery({ params: { employeeId, attendanceGenerationId, isGetEmployeeWiseSalaryDetails: true } }, { skip: !(employeeId && attendanceGenerationId) });
    let attendanceData = employeeData?.data || [];
    return (
        <div className='p-2 mt-4'>
            <div className='text-center font-bold text-2xl '> Employee Attendance Details</div>
            <div className='grid grid-cols-5 mt-5 gap-3'>
                <DisabledInput name={'Emp.Name'} value={attendanceData[0]?.name} />
                <DisabledInput name={'Emp.RegNo'} value={attendanceData[0]?.regNo} />
                <EmployeeCategoryDropdown selected={employeeCategoryId} readOnly />
            </div>
            {
                isWeekly
                    ?
                    <WeeklyEmployee attendanceData={attendanceData} />
                    :
                    <MonthlyEmployee attendanceData={attendanceData} />
            }
        </div>
    )
}

export default AttendanceBreakUp
