import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { DisabledInput } from "../../../Inputs"
import {
  useGetAttendanceGenerationQuery,
  useGetAttendanceGenerationByIdQuery,
  useAddAttendanceGenerationMutation,
  useUpdateAttendanceGenerationMutation,
  useDeleteAttendanceGenerationMutation,
} from '../../../redux/services/AttendanceGenerationService'

import { getDateFromDateTime } from '../../../Utils/helper';
import Modal from "../../../UiComponents/Modal";
import SearchReport from './SearchReport';
import moment from 'moment';
import EmployeeCategoryDropdown from '../../../Basic/ReusableComponents/EmployeeCategoryDropdown';
import { GenerateButton } from '../../../Buttons';
import AttendanceGenerationDetails from './AttendanceGenerationDetail';


export default function Form({ categoryId, employeeCategoryName }) {
  const MODEL = `${employeeCategoryName} Attendance Generation`;
  const today = new Date()
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [remarks, setRemarks] = useState('');
  const [employeeCategoryId, setEmployeeCategoryId] = useState(categoryId);
  const [readOnly, setReadOnly] = useState(false);
  const [formReport, setFormReport] = useState(false);
  const [attendanceGenerationDetails, setAttendanceGenerationDetails] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalDays, setTotalDays] = useState(0);
  const [totalSundays, setTotalSundays] = useState(0);
  const [totalWorkingDays, setTotalWorkingDays] = useState(0);
  const [totalNationalHolidays, setTotalNationalHolidays] = useState(0);

  const [id, setId] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )

  const { data: allData } = useGetAttendanceGenerationQuery({ params: { branchId }, });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetAttendanceGenerationByIdQuery(id, { skip: !id });

  const [addData] = useAddAttendanceGenerationMutation();
  const [updateData] = useUpdateAttendanceGenerationMutation();
  const [removeData] = useDeleteAttendanceGenerationMutation();

  const getNextDocId = useCallback(() => {

    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      if (data?.docId) {
        setDocId(data.docId);
      }
      setEmployeeCategoryId(data?.employeeCategoryId || categoryId);
      setRemarks(data?.remarks || '');
      setAttendanceGenerationDetails(data?.AttendanceGenerationDetails || []);

      setStartDate(data?.startDate || "");
      setEndDate(data?.endDate || '');
      setTotalDays(data?.totalDays || 0);
      setTotalSundays(data?.totalSundays || 0);
      setTotalWorkingDays(data?.totalWorkingDays || 0);
      setTotalWorkingDays(data?.totalWorkingDays || 0);
      setTotalNationalHolidays(data?.totalNationalHolidays || 0);

      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  const data = {
    branchId,
    employeeCategoryId,
    remarks,
    id,
    attendanceGenerationDetails
  }

  const validateData = (data) => {
    if (!data.employeeCategoryId) {
      toast.info("Employee Category is Required...!!!")
      return false;
    }
    return true;
  }



  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        if (text == "Updated") {
          setId("")
          syncFormWithDb(undefined)
        } else {
          setId(returnData?.data?.id)
        }
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
    } catch (error) {
      console.log("handle")
    }
  }

  const saveData = () => {
    if (!validateData(data)) {
      return
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setId("");
    getNextDocId();
    setReadOnly(false);
    syncFormWithDb(undefined);
  }

  return (


    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-full bg-theme'>
      <Modal

        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} categoryId={categoryId} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2'>
          <div className='col-span-4 grid md:grid-cols-1 border'>
            <div className='mr-1 md:ml-2'>
              <fieldset className='frame my-1'>
                <legend className='sub-heading'> {employeeCategoryName}</legend>
                <div className='grid grid-cols-5 my-2 p-2 gap-2'>
                  <DisabledInput name="Trans.No" value={docId} required={true} readOnly={readOnly} />
                  <DisabledInput name="Trans. 
                           Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <EmployeeCategoryDropdown selected={employeeCategoryId} setSelected={setEmployeeCategoryId} readOnly required />
                  {/* <TextArea name={"Remarks"} inputClass={"text-right"} value={remarks} setValue={setRemarks} readOnly={readOnly} /> */}
                  {!id &&
                    <GenerateButton onClick={() => { saveData() }} />
                  }
                  {id &&
                    <>
                      <DisabledInput name="Start. 
                  Date" value={getDateFromDateTime(startDate)} type={"Date"} required={true} readOnly={readOnly} />
                      <DisabledInput name="End. 
                  Date" value={getDateFromDateTime(endDate)} type={"Date"} required={true} readOnly={readOnly} />
                      <DisabledInput name="Total Days" value={totalDays} required={true} readOnly={readOnly} />
                      <DisabledInput name="Sundays" value={totalSundays} required={true} readOnly={readOnly} />
                      <DisabledInput name="Working Days" value={totalWorkingDays} required={true} readOnly={readOnly} />
                      <DisabledInput name="National Holidays" value={totalNationalHolidays} required={true} readOnly={readOnly} />
                    </>
                  }
                </div>
              </fieldset>
              {id &&
                <AttendanceGenerationDetails attendanceGenerationDetails={attendanceGenerationDetails} setAttendanceGenerationDetails={setAttendanceGenerationDetails} readOnly={readOnly || childRecord.current > 0} id={id} employeeCategoryId={employeeCategoryId} employeeCategoryName={employeeCategoryName} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
