import React from 'react'
import { TICK_ICON } from '../../../icons'
import { getDateFromDateTimeToDisplay, groupBy } from '../../../Utils/helper'

const WeeklyEmployee = ({ attendanceData }) => {
    let groups = groupBy(attendanceData, 'date');

    return (
        <div
            className="min-h-[300px] overflow-auto"
        >
            <table className="table-auto text-center w-full">
                <thead className="border-2 table-header">
                    <tr className='h-2'>
                        <th
                            className="border-2  top-0 stick-bg w-10"
                        >
                            S. no.
                        </th>
                        <th
                            className="border-2  top-0 stick-bg"
                        >
                            <div>Date</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Party</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Project</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Full Shift</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Half Shift</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>One Half Shift</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Leave</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Permission(Hours)</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>OT(Hours)</div>
                        </th>
                    </tr>
                </thead>
                <tbody className="border-2">
                    {Object.entries(groups).map(([date, values]) =>
                        <>
                            {values.map((dataObj, index) =>
                                <tr
                                    className="border-2  text-sm cursor-pointer"
                                >
                                    {(index == 0) &&
                                        <>
                                            <td className='py-1 border-r' rowSpan={values.length}>
                                                {index + 1}
                                            </td>
                                            <td className='py-1 border-r' rowSpan={values.length} >
                                                {getDateFromDateTimeToDisplay(dataObj?.date)}
                                            </td>
                                        </>
                                    }
                                    <td className='py-1' >
                                        <div className='w-52'>
                                            {dataObj?.partyName}
                                        </div>
                                    </td>
                                    <td className='py-1' >
                                        <div className='w-52'>
                                            {dataObj?.projectName}
                                        </div>
                                    </td>
                                    <td className='py-1' >
                                        {(dataObj.shiftLength === 'full') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' >
                                        {(dataObj.shiftLength === 'half') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' >
                                        {(dataObj.shiftLength === 'oneHalf') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' >
                                        {(dataObj.shiftLength === 'leave') && <>{TICK_ICON}</>}
                                    </td>
                                    <td className='py-1' >
                                        <input type="number" min={0} className='text-right' value={dataObj.permissionHours} disabled />
                                    </td>
                                    <td className='py-1' >
                                        <input type="number" min={0} className='text-right border w-10 p-1' value={dataObj.overTimeHours} disabled />
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default WeeklyEmployee
