import React from 'react'
import { useGetProjectQuery } from '../../../redux/services/ProjectService'
import {
    ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Loader,
    Accordion,
    AccordionTitle,
    Icon,
    AccordionContent
} from "semantic-ui-react"
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper'
import { useState } from 'react'
import { Fragment } from 'react'

const ProjectWiseShiftDetails = ({ projectId }) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const { data } = useGetProjectQuery({ params: { isGetProjectShiftDetails: true, projectId, } }, { skip: !projectId })
    const projectData = data?.data;
    if (!projectData) {
        return <Loader />
    }
    const projectDateWiseBreakUp = projectData?.projectDateWiseBreakUp || [];
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }
    function sumArray(field) {
        return projectDateWiseBreakUp.reduce((a, c) => a + parseFloat(c?.[field] || 0), 0)
    }
    return (
        <>
            <ModalHeader>
                {projectData?.projectName} [{projectData?.docId}]
            </ModalHeader>
            <>
                {(projectDateWiseBreakUp.length == 0)
                    ?
                    <div className='text-center text-xl'>
                        No Data Found...
                    </div>
                    :
                    <ModalContent>
                        <Accordion fluid styled>
                            <AccordionTitle
                                active
                            >
                                <div className='grid grid-cols-4 justify-center text-center'>
                                    <span >
                                        Date
                                    </span>
                                    <span>
                                        Shift
                                    </span>
                                    <span>
                                        Permission
                                    </span>
                                    <span>
                                        OT&nbsp;Hours
                                    </span>
                                </div>
                            </AccordionTitle>
                            {projectDateWiseBreakUp.map((dateWise, index) =>
                                <Fragment key={index}>
                                    <AccordionTitle
                                        active={index == activeIndex}
                                        index={index}
                                        onClick={handleClick}
                                    >
                                        <div className='grid grid-cols-4 justify-center text-center'>
                                            <span className='text-start'>
                                                <Icon name='dropdown' />
                                                {getDateFromDateTimeToDisplay(dateWise?.date)}
                                            </span>
                                            <span>
                                                {dateWise?.totalShift}
                                            </span>
                                            <span>
                                                {dateWise?.permissionHours}
                                            </span>
                                            <span>
                                                {dateWise?.overTimeHours}
                                            </span>
                                        </div>
                                    </AccordionTitle>
                                    <AccordionContent active={activeIndex === index}>
                                        {(dateWise["employeeWise"] || []).map((emp, jIndex) =>
                                            <div className='grid grid-cols-4 justify-center items-center text-center mt-1' key={jIndex}>
                                                <span className='text-start'>
                                                    {emp?.employeeName}
                                                </span>
                                                <span>
                                                    {emp?.totalShift}
                                                </span>
                                                <span>
                                                    {emp?.permissionHours}
                                                </span>
                                                <span>
                                                    {emp?.overTimeHours}
                                                </span>
                                            </div>
                                        )}
                                    </AccordionContent>
                                </Fragment>
                            )}
                            <AccordionTitle
                                active
                            >
                                <div className='grid grid-cols-4 justify-center text-center'>
                                    <span >
                                        Total
                                    </span>
                                    <span>
                                        {sumArray('totalShift')}
                                    </span>
                                    <span>
                                        {sumArray('permissionHours')}
                                    </span>
                                    <span>
                                        {sumArray('overTimeHours')}
                                    </span>
                                </div>
                            </AccordionTitle>
                        </Accordion>
                    </ModalContent>
                }
            </>
        </>

    )
}

export default ProjectWiseShiftDetails
