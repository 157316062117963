import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LOAN_ENTRY_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LoanEntryApi = createApi({
  reducerPath: "LoanEntry",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["LoanEntry"],
  endpoints: (builder) => ({
    getLoanEntry: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: LOAN_ENTRY_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LOAN_ENTRY_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LoanEntry"],
    }),
    getLoanEntryById: builder.query({
      query: (id) => {
        return {
          url: `${LOAN_ENTRY_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LoanEntry"],
    }),
    addLoanEntry: builder.mutation({
      query: (payload) => ({
        url: LOAN_ENTRY_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["LoanEntry"],
    }),
    updateLoanEntry: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LOAN_ENTRY_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LoanEntry"],
    }),
    deleteLoanEntry: builder.mutation({
      query: (id) => ({
        url: `${LOAN_ENTRY_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LoanEntry"],
    }),
  }),
});

export const {
  useGetLoanEntryQuery,
  useGetLoanEntryByIdQuery,
  useAddLoanEntryMutation,
  useUpdateLoanEntryMutation,
  useDeleteLoanEntryMutation,
} = LoanEntryApi;

export default LoanEntryApi;
