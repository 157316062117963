import React, { useState } from 'react'
import { substract } from '../../../helper';
import { toast } from 'react-toastify';
import { Modal } from '../../../Inputs';
import AttendanceBreakUp from './AttendanceBreakUp';
import { VIEW } from '../../../icons';
import { useGetEmployeeCategoryByIdQuery } from '../../../redux/services/EmployeeCategoryMasterService';

const AttendanceGenerationDetails = ({
    attendanceGenerationDetails, setAttendanceGenerationDetails, readOnly, id, employeeCategoryId, employeeCategoryName
}) => {
    const [previewEmployeeId, setPreviewEmployeeId] = useState("")
    function handleChange(value, field, index) {
        setAttendanceGenerationDetails(prev => {
            let newItems = structuredClone(prev);
            newItems[index][field] = value;
            if (field == "loanDeductionAmount" || field == "foodAllowance") {
                newItems[index]["salaryAfterDeduction"] =
                    substract(parseFloat(newItems[index]["salary"] || 0), parseFloat(newItems[index]["loanDeductionAmount"] || 0)) + parseFloat(newItems[index]["foodAllowance"] || 0);

            }
            return newItems
        })
    }
    function handleAddFoodAllowance(value, field, index) {
        setAttendanceGenerationDetails(prev => {
            let newItems = structuredClone(prev);
            newItems[index][field] = value;


            return newItems
        })
    }
    const { data: employeeCategoryData } = useGetEmployeeCategoryByIdQuery(employeeCategoryId, { skip: !employeeCategoryId })

    let salaryType = employeeCategoryData?.data?.salaryType || '';

    let isWeekly = salaryType == "Weekly";
    return (
        <>
            <Modal
                isOpen={previewEmployeeId}
                onClose={() => setPreviewEmployeeId('')}
                widthClass={"px-2 h-[90%] w-[90%]"}
            >
                <AttendanceBreakUp employeeId={previewEmployeeId} attendanceGenerationId={id} employeeCategoryId={employeeCategoryId} isWeekly={isWeekly} />
            </Modal>
            <div id='registrationFormReport' className=" w-full">
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10">Attendance Details </div>
                </div>
                <>
                    <div
                        className=""
                    >
                        <table className="table-fixed text-center w-full">
                            <thead className="border-2 table-header">
                                <tr className='h-2'>
                                    <th
                                        className="border-2  top-0 stick-bg w-10"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>Employee</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Emp.Reg.no</div>
                                    </th>
                                    <th className="border-2 top-0 stick-bg">
                                        <div>Salary Perday</div>
                                    </th>
                                    <th className="border-2 top-0 stick-bg">
                                        <div>{isWeekly ? "Total Shift" : "Worked Days"}</div>
                                    </th>

                                    <th

                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>{isWeekly ? "Total Shift Salary" : "Worked Days Salary"}</div>
                                    </th>
                                    <th

                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>OT Hours</div>
                                    </th>
                                    <th

                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>OT Hours Salary</div>
                                    </th>
                                    {!isWeekly ? <th
                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>Permission Hours</div>
                                    </th> : ''}
                                    {!isWeekly ? <th

                                        className="border-2  top-0 stick-bg "
                                    >
                                        <div>Permission Hours Deduction Salary</div>
                                    </th> : ''}
                                    {!isWeekly ? <th className="border-2 top-0 stick-bg">
                                        <div>Paid Leave Applied</div>
                                    </th> : ''}
                                    {!isWeekly ? <th className="border-2 top-0 stick-bg">
                                        <div>Paid Leave Amt</div>
                                    </th> : ''}
                                    {!isWeekly ? <th className="border-2 top-0 stick-bg">
                                        <div> Pay for NationalHoliday</div>
                                    </th> : ''}

                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Salary</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Advance Loan Balance</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Loan Deduction Amount</div>
                                    </th>
                                    {isWeekly ? <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Food Allowance</div>
                                    </th> : ''}
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Final Salary</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>View</div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="border-2 text-right">
                                {(attendanceGenerationDetails || []).map((dataObj, index) => (
                                    <tr
                                        tabIndex={0}
                                        key={dataObj.id}
                                        className="border-2 table-row cursor-pointer"
                                    >
                                        <td className='p-1 text-center'> {(index + 1)}</td>
                                        <td className='p-1 text-start'>{dataObj?.employeeName}</td>
                                        <td className='p-1 text-start'> {dataObj?.employeeRegNo}</td>
                                        <td className='p-1'>{dataObj?.salaryPerDay}
                                        </td>
                                        <td className='p-1 text-center'>{dataObj?.totalWorkedDays} </td>
                                        <td className='p-1'>{dataObj?.salaryForWorkedDays} </td>
                                        <td className='p-1'>{dataObj?.totalOTHours} </td>
                                        <td className='p-1'>{dataObj?.salaryForOTHours} </td>
                                        {!isWeekly ? <td className='p-1'>{dataObj?.totalPermissionHours} </td> : ""}
                                        {!isWeekly ? <td className='p-1'>{dataObj?.salaryDeductionForPermissionHours} </td> : ''}
                                        {!isWeekly ? <td className='p-1'> {dataObj?.paidLeaveApplied} </td> : ''}
                                        {!isWeekly ? <td className='p-1'> {dataObj?.paidLeaveAmt} </td> : ''}
                                        {!isWeekly ? <td className='p-1'>{dataObj?.extraPayforNationalHolidayWork}</td> : ''}

                                        <td className='p-1'>{dataObj?.salary.toFixed(2)}</td>
                                        <td className='p-1'>{dataObj?.balanceAdvanceLoanAmount}</td>
                                        <td className='p-1'>
                                            <input className='text-right w-full' value={dataObj?.loanDeductionAmount || 0} onChange={(e) => {
                                                if (parseFloat(dataObj?.balanceAdvanceLoanAmount || 0) < parseFloat(e.target.value || 0)) {
                                                    return toast.info("Loan Deduction Amount Can be More Than Balance...!!!")
                                                }
                                                if (parseFloat(dataObj?.salary || 0) < parseFloat(e.target.value || 0)) {
                                                    return toast.info("Loan Deduction Amount Can be More Than Salary...!!!")
                                                }
                                                handleChange(e.target.value, 'loanDeductionAmount', index)
                                            }} disabled={readOnly} />
                                        </td>
                                        {isWeekly ? <td className='p-1'>
                                            <input className='text-right w-full' onChange={(e) => handleChange(e.target.value, 'foodAllowance', index)} value={dataObj?.foodAllowance || 0} disabled={readOnly} />
                                        </td> : ''}
                                        <td className='p-1'>
                                            <input className='text-right w-full' value={dataObj?.salaryAfterDeduction.toFixed(2) || 0} disabled />
                                        </td>
                                        <td className='p-1' onClick={() => { setPreviewEmployeeId(dataObj.employeeId) }}>
                                            <div className='flex w-full items-center justify-center'>
                                                {VIEW}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            </div>
        </>
    )
}

export default AttendanceGenerationDetails