import React, { useEffect } from 'react'
import { useGetEmployeeCategoryQuery } from '../../redux/services/EmployeeCategoryMasterService'
import { DropdownInput } from '../../Inputs';
import { dropDownListObject } from '../../Utils/contructObject';

const EmployeeCategoryDropdown = ({ readOnly, selected, setSelected, isLabel = true, name = "Emp. Category", firstSelected = true }) => {
    const { data: employeeCategoryData } = useGetEmployeeCategoryQuery({ params: { active: true } });
    useEffect(() => {
        if (!firstSelected) return;
        if (selected) return;
        if (!employeeCategoryData?.data) return;
        if ((employeeCategoryData?.data || []).length === 0) return
        setSelected(employeeCategoryData?.data[0]?.id)
    }, [employeeCategoryData, firstSelected, selected, setSelected])
    return (
        <DropdownInput isLabel={isLabel} readOnly={readOnly} name={name} value={selected} setValue={setSelected}
            options={dropDownListObject(employeeCategoryData?.data || [], "name", "id")} />
    )
}

export default EmployeeCategoryDropdown
