import React from 'react'
import { useEffect, useState } from "react";
import { Loader } from "../../../Basic/components";
import { projectStatusItems, showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetProjectQuery } from '../../../redux/services/ProjectService';
import { AddNewButton } from '../../../Buttons';
import { VIEW } from '../../../icons';
import {
    Modal
} from "semantic-ui-react";
import "semantic-ui-css"
import ProjectWiseShiftDetails from './ProjectWiseShiftDetails';
const Report = ({
    onClick, onNew, onNewButton
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");
    const [searchLeadId, setSearchLeadId] = useState("")
    const [searchQuoteId, setSearchQuoteId] = useState("")

    const [searchDocDate, setSearchDocDate] = useState("");
    const [searchPartyName, setSearchPartyName] = useState("");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState()
    const [dataPerPage, setDataPerPage] = useState("100");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [projectStatus, setProjectStatus] = useState("NOTCOMPLETED")
    const [searchLocation, setSearchLocation] = useState("");
    const [searchProjectName, setSearchProjectName] = useState("");
    const [projectId, setProjectId] = useState('');

    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchDocDate, searchLeadId, searchQuoteId, searchPartyName, searchLocation, searchProjectName, projectStatus, fromDate, toDate }

    useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchQuoteId, searchDocId, searchLeadId, searchDocDate, searchPartyName, searchLocation, searchProjectName, projectStatus])


    const { data: allData, isLoading, isFetching } = useGetProjectQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, isProjectReport: true } });


    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])

    const isLoadingIndicator = isLoading || isFetching;

    return (
        <>
            <Modal open={projectId} onClose={() => { setProjectId("") }} closeIcon centered={false} >
                <ProjectWiseShiftDetails projectId={projectId} />
            </Modal>
            <div id='registrationFormReport' className="flex flex-col w-full h-full overflow-auto ">
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10">Project Wise Shift Details</div>
                    <div className=' flex justify-center items-center gap-x-5'>
                        <div className="flex gap-x-1 justify-center items-center">
                            <label htmlFor="id" className={`md:text-start font-bold text-sm`}>
                                From :
                            </label>
                            <input
                                type={"date"}
                                className={`input-field focus:outline-none md:col-span-2 border border-gray-500 text-gray-600 rounded  p-1 text-sm `}
                                id='id'
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}

                            />
                        </div>

                        <div className="flex gap-x-1 justify-center items-center ">
                            <label htmlFor="id" className={`md:text-start  font-bold text-sm`}>
                                To :
                            </label>
                            <input
                                type={"date"}
                                className={`input-field focus:outline-none md:col-span-2 border border-gray-500 text-gray-600 rounded  p-1 text-sm `}
                                id='id'
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}

                            />
                        </div>



                        <div className="flex gap-x-1 justify-center items-center ">
                            <label className={`md:text-start flex text-sm `}>Status</label>
                            <select
                                name="name" className={`${"input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm"} `}
                                value={projectStatus} onChange={(e) => { setProjectStatus(e.target.value); }} >
                                {projectStatusItems?.map((option, index) => <option key={index} value={option.value} >
                                    {option.show}
                                </option>)}
                            </select>
                        </div>
                    </div>
                    {/* <div className='w-60'>
          <DropdownInput name="Status" options={projectStatusItems} value={projectStatus} setValue={setProjectStatus} />

        </div> */}
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        {
                            onNewButton ? <AddNewButton onClick={onNew} /> : <div></div>
                        }

                        <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <>
                    <div className="h-[75vh]  w-full overflow-auto mx-auto my-4 border-2 border-gray-300 rounded-lg text-xs">
                        <table className="table-fixed text-center w-full">
                            <thead className="border-2 table-header bg-gray-100 sticky top-0">
                                <tr className='h-2'>
                                    <th
                                        className="border-2  top-0 stick-bg w-10"
                                    >
                                        S.no
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg flex flex-col"
                                    >
                                        <div>Doc. Id</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchDocId}
                                            onChange={(e) => {
                                                setSearchDocId(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th className="border-2 px-2">
                                        <div>Quotes No</div>
                                        <input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border border-gray-400 rounded-lg mt-1"
                                            placeholder="Search"
                                            value={searchQuoteId}
                                            onChange={(e) => setSearchQuoteId(e.target.value)}
                                        />
                                    </th>
                                    <th className="border-2 px-2">
                                        <div>Leads No</div>
                                        <input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border border-gray-400 rounded-lg mt-1"
                                            placeholder="Search"
                                            value={searchLeadId}
                                            onChange={(e) => setSearchLeadId(e.target.value)}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Doc. Date</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchDocDate}
                                            onChange={(e) => {
                                                setSearchDocDate(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th

                                        className="border-2  top-0 stick-bg flex flex-col"
                                    >
                                        <div>Client</div><input
                                            type="text"
                                            className="text-black  h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchPartyName}
                                            onChange={(e) => {
                                                setSearchPartyName(e.target.value);
                                            }}
                                        />
                                    </th>


                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Location</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchLocation}
                                            onChange={(e) => {
                                                setSearchLocation(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>ProjectName</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchProjectName}
                                            onChange={(e) => {
                                                setSearchProjectName(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg"
                                    >
                                        <div>Shift Details</div>
                                    </th>
                                </tr>
                            </thead>
                            {isLoadingIndicator ?
                                <tbody>
                                    <tr>
                                        <td>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="border-2">
                                    {(allData?.data ? allData?.data : []).map((dataObj, index) => (
                                        <tr
                                            key={dataObj.id}
                                            className="border-2 table-row cursor-pointer"
                                        >
                                            <td className='py-1 h-9'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                            <td className='py-1'> {dataObj.docId}</td>
                                            <td className="py-1">
                                                {dataObj?.Quotes.map(item => item.docId)}
                                            </td>
                                            <td className="py-1">
                                                {dataObj?.Quotes.map(item => item.LeadForm?.docId)}
                                            </td>

                                            <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>
                                            <td className='py-1'>{dataObj?.Client?.name}</td>
                                            <td className='py-1'>{dataObj?.location}</td>
                                            <td className='py-1'>{dataObj?.projectName}</td>
                                            <td className='py-1'>
                                                <button onClick={() => { setProjectId(dataObj.id) }}>
                                                    {VIEW}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            }
                        </table>
                    </div>
                </>
                <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">

                    <ReactPaginate
                        previousdiv={"<"}
                        nextdiv={">"}
                        breakdiv={"..."}
                        breakClassName={"break-me"}
                        forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                        pageCount={Math.ceil(totalCount / dataPerPage)}
                        marginPagesDisplayed={1}
                        onPageChange={handleOnclick}
                        containerClassName={"flex justify-center m-2 gap-5 items-center"}
                        pageClassName={"border custom-circle text-center"}
                        disabledClassName={"p-1 bg-gray-200"}
                        previousLinkClassName={"border p-1 text-center"}
                        nextLinkClassName={"border p-1"}
                        activeClassName={"bg-blue-900 text-white px-2"} />
                </div>
            </div>
        </>
    )
}

export default Report