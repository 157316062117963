import React from 'react'
import { TICK_ICON } from '../../../icons'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper'

const MonthlyEmployee = ({ attendanceData }) => {
    return (
        <div
            className="h-[500px] overflow-auto"
        >
            <table className="table-fixed text-center w-full ">
                <thead className="border-2 table-header">
                    <tr className='h-2'>
                        <th
                            className="border-2  top-0 stick-bg w-10"
                        >
                            S. no.
                        </th>
                        <th
                            className="border-2  top-0 stick-bg "
                        >
                            <div>Date</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Full Day</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Half Day</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Leave</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Permission(Hours)</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>OT(Hours)</div>
                        </th>
                    </tr>
                </thead>
                <tbody className="border-2">
                    {(attendanceData).map((dataObj, index) => (
                        <tr
                            key={dataObj.id}
                            className="border-2 text-sm cursor-pointer"
                        >
                            <td className='py-1'> {(index + 1)}</td>
                            <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.date)}</td>
                            <td className='py-1' >
                                {(dataObj.shiftLength === 'full') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1'>
                                {(dataObj.shiftLength === 'half') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1'>
                                {(dataObj.shiftLength === 'leave') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1' >
                                <input type="number" min={0} className='text-right' value={dataObj.permissionHours} disabled />
                            </td>
                            <td className='py-1' >
                                <input type="number" min={0} className='text-right' value={dataObj.overTimeHours} disabled />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MonthlyEmployee
