import { configureStore } from "@reduxjs/toolkit";
import { openTabs } from "./features";
import {
  countryMasterApi, pageMasterApi, stateMasterApi,
  cityMasterApi, departmentMasterApi, employeeCategoryMasterApi,
  finYearMasterApi, rolesMasterApi, employeeMasterApi, userMasterApi,
  branchMasterApi, companyMasterApi, pageGroupMasterApi, productBrandMasterApi, productCategoryMasterApi, productMasterApi, partyMasterApi, partyCategoryMasterApi, purchaseBillApi, stockApi, salesBillApi, purchaseReturnApi, salesReturnApi, uomMasterApi,
  productSubCategoryMasterApi,
  quotesApi,
  leadFormApi,
  projectApi,
  invoiceApi,
  HolidaysCalenderMasterApi,
  AttendanceGenerationApi,

} from "./services"
import projectPaymentFormApi from "./services/ProjectPaymentService";
import LoanEntryApi from "./services/LoanEntryService";
import { Loader } from "../Basic/components";







const commonReducers = {
  openTabs,
  countryMaster: countryMasterApi.reducer,
  pageMaster: pageMasterApi.reducer,
  stateMaster: stateMasterApi.reducer,
  cityMaster: cityMasterApi.reducer,
  departmentMaster: departmentMasterApi.reducer,
  employeeCategoryMaster: employeeCategoryMasterApi.reducer,
  finYearMaster: finYearMasterApi.reducer,
  roleMaster: rolesMasterApi.reducer,
  userMaster: userMasterApi.reducer,
  employeeMaster: employeeMasterApi.reducer,
  branchMaster: branchMasterApi.reducer,
  companyMaster: companyMasterApi.reducer,
  pageGroupMaster: pageGroupMasterApi.reducer,
  productBrandMaster: productBrandMasterApi.reducer,
  productCategoryMaster: productCategoryMasterApi.reducer,
  productSubCategoryMaster: productSubCategoryMasterApi.reducer,
  productMaster: productMasterApi.reducer,
  partyMaster: partyMasterApi.reducer,
  partyCategoryMaster: partyCategoryMasterApi.reducer,
  purchaseBill: purchaseBillApi.reducer,
  stock: stockApi.reducer,
  salesBill: salesBillApi.reducer,
  purchaseReturn: purchaseReturnApi.reducer,
  salesReturn: salesReturnApi.reducer,
  uomMaster: uomMasterApi.reducer,
  quotes: quotesApi.reducer,
  leadForm: leadFormApi.reducer,
  projectPayment: projectPaymentFormApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [HolidaysCalenderMasterApi.reducerPath]: HolidaysCalenderMasterApi.reducer,
  [LoanEntryApi.reducerPath]: LoanEntryApi.reducer,
  [AttendanceGenerationApi.reducerPath]: AttendanceGenerationApi.reducer
}
const commonMiddleware = [countryMasterApi.middleware,
pageMasterApi.middleware,
stateMasterApi.middleware,
cityMasterApi.middleware,
departmentMasterApi.middleware,
employeeCategoryMasterApi.middleware,
finYearMasterApi.middleware,
rolesMasterApi.middleware,
userMasterApi.middleware,
employeeMasterApi.middleware,
branchMasterApi.middleware,
companyMasterApi.middleware,
pageGroupMasterApi.middleware,
productBrandMasterApi.middleware,
productCategoryMasterApi.middleware,
productSubCategoryMasterApi.middleware,
productMasterApi.middleware,
partyMasterApi.middleware,
partyCategoryMasterApi.middleware,
purchaseBillApi.middleware,
stockApi.middleware,
salesBillApi.middleware,
purchaseReturnApi.middleware,
salesReturnApi.middleware,
uomMasterApi.middleware,
quotesApi.middleware,
leadFormApi.middleware,
projectApi.middleware,
invoiceApi.middleware,
projectPaymentFormApi.middleware,
HolidaysCalenderMasterApi.middleware,
LoanEntryApi.middleware,
AttendanceGenerationApi.middleware
];




const store = configureStore({
  reducer: {
    ...commonReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(commonMiddleware),
});

export default store;