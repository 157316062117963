import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { TextInput, DisabledInput, TextArea, DateInput, } from "../../../Inputs"
import {
  useGetLoanEntryQuery,
  useGetLoanEntryByIdQuery,
  useAddLoanEntryMutation,
  useUpdateLoanEntryMutation,
  useDeleteLoanEntryMutation,
} from '../../../redux/services/LoanEntryService'

import { getDateFromDateTime } from '../../../Utils/helper';
import Modal from "../../../UiComponents/Modal";
import SearchReport from './SearchReport';
import moment from 'moment';
import EmployeeCategoryDropdown from '../../../Basic/ReusableComponents/EmployeeCategoryDropdown';
import EmployeeDropdown from '../../ReusableComponents/EmployeeDropdown';

const MODEL = "Loan Entry";

export default function Form() {
  const today = new Date()
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [loanAmount, setLoanAmount] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [employeeCategoryId, setEmployeeCategoryId] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [formReport, setFormReport] = useState(false)
  const [loanDate, setLoanDate] = useState(getDateFromDateTime(today));

  const [id, setId] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )

  const { data: allData } = useGetLoanEntryQuery({ params: { branchId }, });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetLoanEntryByIdQuery(id, { skip: !id });

  const [addData] = useAddLoanEntryMutation();
  const [updateData] = useUpdateLoanEntryMutation();
  const [removeData] = useDeleteLoanEntryMutation();

  const getNextDocId = useCallback(() => {

    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {

      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.createdAt) setDate(getDateFromDateTime(data?.createdAt));
      if (data?.loanDate) setLoanDate(getDateFromDateTime(data?.loanDate));
      else setLoanDate(getDateFromDateTime(new Date()));
      if (data?.docId) {
        setDocId(data.docId);
      }
      setLoanAmount(data?.loanAmount || 0);
      setEmployeeCategoryId(data?.employeeCategoryId || '');
      setEmployeeId(data?.employeeId || '');
      setRemarks(data?.remarks || '');
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  const data = {
    branchId,
    loanAmount,
    employeeId,
    employeeCategoryId,
    remarks,
    id,
    loanDate
  }

  const validateData = (data) => {
    if (!data.employeeCategoryId) {
      toast.info("Employee Category is Required...!!!")
      return false;
    }
    if (!data.employeeId) {
      toast.info("Employee is Required...!!!")
      return false;
    }
    if (!data.loanDate) {
      toast.info("Loan Date is Required...!!!")
      return false;
    }
    if (!data.loanAmount) {
      toast.info("Loan Amount is Required...!!!")
      return false;
    }
    return true;
  }



  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
    } catch (error) {
      console.log("handle")
    }
  }

  const saveData = () => {
    if (!validateData(data)) {
      return
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        const returnData = await removeData(id).unwrap();
        if (returnData.statusCode === 0) {
          setId("")
          syncFormWithDb(undefined)
          toast.success("Deleted Successfully");
        } else {
          toast.error(returnData?.message)
        }
      } catch (error) {
        toast.error(error?.data?.message)
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setId("");
    getNextDocId();
    setReadOnly(false);
    syncFormWithDb(undefined);
  }

  return (


    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-full bg-theme'>
      <Modal

        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2'>
          <div className='col-span-4 grid md:grid-cols-1 border'>
            <div className='mr-1 md:ml-2'>
              <fieldset className='frame my-1'>
                <legend className='sub-heading'>Loan</legend>
                <div className='grid grid-cols-4 my-2 p-2 gap-2'>
                  <DisabledInput name="Trans.No" value={docId} required={true} readOnly={readOnly} />
                  <DisabledInput name="Trans. 
                           Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <DateInput name="Loan Date" value={loanDate} type={"Date"} required={true} setValue={setLoanDate} />
                  <EmployeeCategoryDropdown selected={employeeCategoryId} setSelected={setEmployeeCategoryId} readOnly={readOnly} required />
                  <EmployeeDropdown withSearch employeeCategoryId={employeeCategoryId} selected={employeeId} setSelected={setEmployeeId} readOnly={readOnly} required />
                  <TextArea name={"Remarks"} inputClass={"text-right"} value={remarks} setValue={setRemarks} readOnly={readOnly} />
                  <TextInput name={"Amount"} inputClass={"text-right"} type={'number'} value={loanAmount} setValue={setLoanAmount} readOnly={readOnly} />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
