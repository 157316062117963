import React from 'react'
import { DropdownInput, DropdownWithSearchUpdated, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetEmployeeQuery } from '../../redux/services/EmployeeMasterService';

const EmployeeDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, employeeCategoryId, withSearch, required }) => {
    const params = getCommonParams();
    const { data } = useGetEmployeeQuery({ params: { ...params, employeeCategoryId } });
    if (withSearch) {
        return <DropdownWithSearchUpdated name="Employee" required={required} className='w-full text-start' options={data?.data || []} labelField='name' value={selected} setValue={setSelected} readOnly={readOnly} searchBy={"name"} />
    }
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className=' table-data-input'>
                    <option value="">Select</option>
                    {(data ? data.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(data ? data.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(data ? data.data : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default EmployeeDropdown
