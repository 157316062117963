import React, { useEffect, useState } from 'react'
import moment from 'moment/moment';
import Calendar from './Calendar';
import HolidayEntryModalForm from './HolidayEntryModalForm';
import { Modal } from "../../../Inputs"
import toasterTrigger from "../../../Utils/toastTrigger";
import 'react-toastify/dist/ReactToastify.css';
import HolidayReport from './HolidayReport';
import { filterByMonth } from './helper';
import { useNavigate } from 'react-router-dom';
import { useGetHolidaysCalenderQuery } from '../../../redux/services/HolidaysServices';
import AttendanceForm from "./AttendanceForm";

const HolidayCalendar = () => {
  const [month, setMonth] = useState(new Date());
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [openHolidayForm, setOpenHolidayForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [updateType, setUpdateType] = useState("Attendance");

  const { data: allData, isLoading, isFetching } = useGetHolidaysCalenderQuery({});

  useEffect(() => {
    if (!allData?.data) return
    setHolidays(allData.data)
  }, [allData, isLoading, isFetching]);

  useEffect(() => {
    const selectedMonth = sessionStorage.getItem("selectedMonth")
    if (selectedMonth) {
      setMonth(selectedMonth);
      sessionStorage.removeItem("selectedMonth")
    }
  }, []);
  useEffect(toasterTrigger, []);
  function handleDateClick(date) {
    setMonth(date)
    setSelectedDate(date);
    setOpenOptionsModal(true);
  }

  const tabs = {
    "Attendance": <AttendanceForm selectedDate={selectedDate} />,
    "Holiday": <HolidayEntryModalForm holidays={holidays} selectedDate={selectedDate} onClose={() => { setOpenHolidayForm(false) }} setSelectedDate={setSelectedDate} />
  }

  return (
    <div className=''>
      <Modal isOpen={openOptionsModal} onClose={() => setOpenOptionsModal(false)} widthClass={"w-[95%] h-[80%]"} >
        <div className='m-5'>
          {Object.keys(tabs).map(tab =>
            <button className={`p-1  ${(updateType == tab) ? "bg-gray-600 text-white" : "text-gray-600 bg-gray-200"}`}
              onClick={() => { setUpdateType(tab) }}>{tab}</button>
          )}
          {tabs[updateType]}
        </div>
      </Modal>
      <header className='font-bold  bg-blue-400  flex items-center justify-between' style={{ height: "10%" }}>
        <span className='justify-end flex text-white text-xl mx-20'>Attendance </span>
        <div className='flex gap-2 px-2'>
          <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-1 rounded' onClick={() => setMonth(moment.utc(month).subtract(1, "M"))}>
            {"< Prev"}
          </button>
          <input style={{ colorScheme: "dark" }} type="month" name="" id="" className='rounded border select-none text-center bg-blue-500 hover:bg-blue-700 text-white font-bold' value={moment.utc(month).format("YYYY-MM")} onChange={(e) => setMonth(e.target.value)} />
          <button className='rounded bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4' onClick={() => setMonth(moment.utc(month).add(1, "M"))}>
            {"Next >"}
          </button>
        </div>
      </header>
      <div className='w-full' style={{ height: "90%" }}>
        <div className='w-1/6 float-left h-full py-15 overflow-y-auto'>
          <HolidayReport holidays={holidays} onClick={(date) => setMonth(date)} />
        </div>
        <div className='w-5/6 float-right pt-11 h-full' >
          <Calendar holidays={filterByMonth(holidays, month)} handleDateClick={handleDateClick} month={month} setOpenModal={setOpenOptionsModal} setSelectedDate={setSelectedDate} />
        </div>
      </div>
    </div>
  )
}

export default HolidayCalendar
