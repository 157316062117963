import validator from 'validator';
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-dropdown-select';
import { findFromList, findFromListCustom, findFromListFromMatchField, findFromListReturnsItem } from '../Utils/helper';



export const handleOnChange = (event, setValue) => {
    const inputValue = event.target.value;
    const inputSelectionStart = event.target.selectionStart;
    const inputSelectionEnd = event.target.selectionEnd;

    const upperCaseValue = inputValue.toUpperCase();

    const valueBeforeCursor = upperCaseValue.slice(0, inputSelectionStart);
    const valueAfterCursor = upperCaseValue.slice(inputSelectionEnd);

    setValue(valueBeforeCursor + inputValue.slice(inputSelectionStart, inputSelectionEnd) + valueAfterCursor);

    // Set the cursor position to the end of the input value
    setTimeout(() => {
        event.target.setSelectionRange(valueBeforeCursor.length + inputValue.slice(inputSelectionStart, inputSelectionEnd).length, valueBeforeCursor.length + inputValue.slice(inputSelectionStart, inputSelectionEnd).length);
    });
};

export const MultiSelectDropdown = ({ name, selected, labelName, setSelected, options, readOnly = false, tabIndex = null, className = "", inputClass }) => {
    return (
        <div className={`flex flex-col mb-4  ${className}`}>
            <label className={`md:text-start flex ${labelName}`} >{name}</label>
            <MultiSelect
                className={`focus:outline-none  border border-gray-500 rounded text-black  ${inputClass}`}
                options={options}
                value={selected}
                onChange={readOnly ? () => { } : setSelected}
                labelledBy="Select"
            />
        </div>
    );
};

export const TextInput = ({ name, type, value, setValue, readOnly, className, inputClass = null, required = false, disabled = false, tabIndex = null, onBlur = null }) => {
    return (

        <div className={`flex flex-col mb-4  ${className}`}>
            <label className={`md:text-start flex text-sm pb-1 ${className}`}>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <input onBlur={onBlur} tabIndex={tabIndex ? tabIndex : undefined} type={type} disabled={disabled} required={required} className={`${"input-field focus:outline-none md:col-span-2 border-gray-500 p-1 bg-white text-sm text-black border rounded"} ${inputClass}`} value={value} onChange={(e) => { type === "number" ? setValue(e.target.value) : handleOnChange(e, setValue) }} readOnly={readOnly} />
        </div>
    )
}

export const LongTextInput = ({ name, type, value, setValue, className, readOnly, required = false, disabled = false, tabIndex = null }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 items-center md:my-0.5 md:px-1 data gap-1'>
            <label className='md:text-start flex'>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <input tabIndex={tabIndex ? tabIndex : undefined} type={type} disabled={disabled} required={required} className={className} value={value} onChange={(e) => { type === "number" ? setValue(e.target.value) : handleOnChange(e, setValue) }} readOnly={readOnly} />
        </div>
    )
}
export const DisabledInput = ({ name, type, value, className = "", textClassName = "", tabIndex = null }) => {
    return (
        <div className={`flex flex-col mb-4 ${className}`}>
            <label className={`text-gray-900 text-sm mb-1 ${className}`}>{name}</label>
            <input
                tabIndex={tabIndex ? tabIndex : undefined}
                type={type}
                className={`bg-white text-gray-900 px-2 py-1 focus:outline-none border border-gray-400 rounded-md text-sm text-black shadow-sm w-full h-7 ${textClassName}`}
                value={value}
                disabled
            />
        </div>
    )
}



export const FloatingLabelInput = ({ label, type = "text", required = false, value, setValue, autoFocus = false, disabled = false }) => {
    const [focus, setFocus] = useState(autoFocus);
    return (
        <div className='static flex flex-col p-6'>
            <label className={(focus || value) ? "z-0 absolute -translate-y-6 duration-300 " : "absolute mx-1 duration-300 text-gray-400"}>{label}</label>
            <input disabled={disabled} id={label} type={type} className="z-10 transparent border-2 rounded" required={required} value={value} onFocus={(e) => setFocus(true)} onBlur={(e) => setFocus(false)} placeholder={label} onChange={(e) => { setValue(e.target.value) }} autoFocus={autoFocus} />
        </div>
    )
}

export const LongDisabledInput = ({ name, type, value, className, tabIndex = null }) => {
    return (
        <div className={`grid grid-flow-col  items-center md:my-0.5 md:px-1 data ${className}`}>
            <label className={`md:text-start flex ${className} `}>{name}</label>
            <input type={type} className={`h-6 border border-gray-500 rounded`} value={value} disabled />
        </div>
    )
}

export const TextArea = ({ name, value, setValue, readOnly, required = false, disabled = false, rows = 2, cols = 30, tabIndex = null }) => {
    return (
        <div className={`flex flex-col mb-4 `}>
            <label className='md:text-start flex text-sm'>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <textarea tabIndex={tabIndex ? tabIndex : undefined} name={name} disabled={disabled} required={required} className='focus:outline-none md:col-span-2 border border-gray-500 rounded text-sm' cols={cols} rows={rows} value={value} onChange={(e) => { handleOnChange(e, setValue); }} readOnly={readOnly}></textarea>
        </div>
    )
}

export const DropdownInput = ({ name, beforeChange = () => { }, isLabel, onBlur = null, options, value, setValue, defaultValue, className, classSelect, readOnly, required = false, disabled = false, clear = false, tabIndex = null, autoFocus = false }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className={`flex flex-col gap-y-1 mb-4`}>
            {isLabel &&
                <label className={`md:text-start flex text-sm ${className}`}>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            }
            {readOnly ?
                <input value={findFromListFromMatchField('value', value, options, 'show')} readOnly className='input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm' />
                :
                <select
                    onBlur={onBlur}
                    autoFocus={autoFocus} tabIndex={tabIndex ? tabIndex : undefined} defaultValue={defaultValue} id='dd'
                    required={required} name="name" className={`${"input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm"}  ${classSelect}`}
                    value={value} onChange={(e) => { beforeChange(); handleOnChange(e); }} disabled={readOnly}>
                    <option value="" hidden={!clear}>Select</option>
                    {options.map((option, index) => <option key={index} value={option.value} >
                        {option.show}
                    </option>)}
                </select>
            }
        </div>
    )
}

export const LongDropdownInput = ({ name, options, value, setValue, defaultValue, className, readOnly, required = false,
    disabled = false, clear = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className='grid grid-cols-12 items-center md:my-1 md:px-1 data'>
            <label className={`text-start col-span-2 `}>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <select tabIndex={tabIndex ? tabIndex : undefined} defaultValue={defaultValue} id='dd' required={required} name="name"
                className={`border border-gray-500 h-6 rounded ${className} col-span-10`} value={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly}>
                <option value="">Select</option>
                {options.map((option, index) => <option key={index} value={option.value} >
                    {option.show}
                </option>)}
            </select>
        </div>
    )
}

export const RadioButton = ({ label, value, onChange, readOnly, className, tabIndex = null }) => {
    return (
        <div className={`flex items-center gap-1 ${className}`}>
            <input type="radio" tabIndex={tabIndex ? tabIndex : undefined} checked={value} onChange={onChange} />
            <label>
                {label}
            </label>
        </div>
    );
};


export const DropdownInputWithoutLabel = ({ options, value, setValue, readOnly, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className='grid grid-cols-1 md:grid-cols-3 items-center md:my-1 md:px-1 data'>
            <select tabIndex={tabIndex ? tabIndex : undefined} required={required} name="name" className='input-field md:col-span-2 border col-span-1 rounded' value={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly}>
                <option value="" hidden>Select</option>
                {options.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
            </select>
        </div>
    )
}


export const CurrencyInput = ({ name, value, setValue, readOnly, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className='grid grid-cols-1 md:grid-cols-3 items-center md:my-1 md:px-1 data'>
            <label htmlFor="id" className='md:text-start flex'>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <input tabIndex={tabIndex ? tabIndex : undefined} type="number" disabled={disabled} required={required} className='input-field focus:outline-none md:col-span-2 border rounded' min="1" step="any" id='id' value={value} onChange={(e) => { handleOnChange(e); }} readOnly={readOnly} />
        </div>
    )
}

const RequiredLabel = ({ name }) => <p>{`${name}`}<span className="text-red-500">*</span> </p>



export const DateInput = ({ name, value, setValue, readOnly, required = false, type = "date", disabled = false, tabIndex = null, inputClass, inputHead }) => {
    return (
        <div className="flex flex-col ">
            <label htmlFor="id" className={`md:text-start text-sm ${inputHead}`}>
                {required ? <RequiredLabel name={name} /> : `${name}`}
            </label>
            <input
                tabIndex={tabIndex ? tabIndex : undefined}
                type={type}
                disabled={disabled}
                required={required}
                className={`input-field focus:outline-none md:col-span-2 border border-gray-500 text-gray-600 rounded w-full p-1 text-sm ${inputClass}`}
                id='id'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                readOnly={readOnly}
            />
        </div>
    )
}


export const LongDateInput = ({ name, value, setValue, readOnly, className, required = false, type = "date", disabled = false, tabIndex = null }) => {

    return (
        <div className='grid grid-flow-col item-center justify-center gap-12 w-56 items-center md:px-1 data'>
            <label htmlFor="id" className='md:text-start flex'>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <input tabIndex={tabIndex ? tabIndex : undefined} type={type} disabled={disabled} required={required} className={`${className} focus:outline-none border border-gray-500 form-border-color rounded h-6`} id='id' value={value} onChange={(e) => { setValue(e.target.value); }} readOnly={readOnly} />
        </div>
    )
}

export const CheckBox = ({ name, value, setValue, readOnly = false, className, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(!value);
    }
    return (
        <div className='items-center md:my-1 md:px-1 data'>
            <label htmlFor="id" className={`md:text-start items-center ${className}`}>
                <input tabIndex={tabIndex ? tabIndex : undefined} type="checkbox" required={required} className='mx-2 py-2' checked={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly} />
                {name}
            </label>
        </div>
    )
}



export const validateEmail = (data) => {
    return validator.isEmail(data);
}

export const validateMobile = (data) => {
    let regMobile = /^[6-9]\d{9}$/;
    return regMobile.test(data);
}

export const validatePan = (data) => {
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return regpan.test(data);
}

export const validatePincode = (data) => {
    return data.toString().length === 6;
}

export const DropdownWithSearch = ({ options, value, setValue, readOnly, searchBy = 'name', labelField = 'name', valueField = 'id', className = '', isSetFirstDefault }) => {
    useEffect(() => {
        if (value) return
        if (isSetFirstDefault) {
            setValue((findFromList(options[0]?.[valueField], options || [], valueField)))
        }
    }, [isSetFirstDefault, options, valueField, value, setValue])
    return (
        <div >
            {readOnly ?
                <span className={className}>
                    {findFromListCustom(valueField, value, options || [], labelField)}
                </span>
                :
                <Select key={value} searchBy={searchBy} options={options || []}
                    closeOnSelect
                    className={`${className} `}
                    disabled={readOnly}
                    labelField={labelField}
                    valueField={valueField}
                    multi={false}
                    values={value ? [{
                        [valueField]: value, name:
                            findFromList(value, options || [], labelField)
                    }] : []}
                    onChange={(value) => {
                        setValue(value[0] ? value[0][valueField] : "")
                    }} />
            }
        </div>
    )
}


export const Modal = ({ isOpen, onClose = null, children, widthClass }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center  justify-center overflow-auto bg-gray-800 bg-opacity-50">
            <div className={`relative bg-white rounded-lg ${widthClass}`}>
                {onClose ?
                    <button
                        className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none "
                        onClick={onClose}
                    >
                        <svg
                            className="h-6 w-6 fill-current "
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Close</title>
                            <path
                                d="M14.348 5.652a.999.999 0 00-1.414 0L10 8.586l-2.93-2.93a.999.999 0 10-1.414 1.414L8.586 10l-2.93 2.93a.999.999 0 101.414 1.414L10 11.414l2.93 2.93a.999.999 0 101.414-1.414L11.414 10l2.93-2.93a.999.999 0 000-1.414z"
                                fillRule="evenodd"
                            />
                        </svg>
                    </button>
                    :
                    ""
                }
                {children}
            </div>
        </div>
    );
};


export const LeedFormDropdownInput = ({ name, beforeChange = () => { }, onBlur = null, options, value, setValue, defaultValue, className, classSelect, readOnly, required = false, disabled = false, clear = false, tabIndex = null, autoFocus = false }) => {
    const handleOnChange = (e) => {
        setValue(!value);
    }
    return (
        <div className={`flex gap-y-1 mb-4 gap-1`}>
            <label className={`md:text-start flex text-sm text-white items-center  ${className}`}>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            {readOnly ?
                <input value={findFromListFromMatchField('value', value, options, 'show')} readOnly className='input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm' />
                :
                <select
                    onBlur={onBlur}
                    autoFocus={autoFocus} tabIndex={tabIndex ? tabIndex : undefined} defaultValue={defaultValue} id='dd'
                    required={required} name="name" className={`${"input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm"}  ${classSelect}`}
                    value={value} onChange={(e) => { beforeChange(); handleOnChange(e); }} disabled={readOnly}>
                    <option value="" hidden={!clear}>LEAD</option>
                    {options.map((option, index) => <option key={index} value={option.value} >
                        {option.show}
                    </option>)}
                </select>
            }
        </div>
    )
}


export const DropdownWithSearchUpdated = ({ name, options, value, setValue, readOnly, searchBy = 'name', labelField = 'name', valueField = 'id', className = '', isSetFirstDefault, required }) => {
    useEffect(() => {
        if (value) return
        if (isSetFirstDefault) {
            setValue((findFromList(options[0]?.[valueField], options || [], valueField)))
        }
    }, [isSetFirstDefault, options, valueField, value, setValue])
    return (
        <div className='z-10 text-sm'>
            {name &&
                <div>
                    {name}
                </div>
            }
            {readOnly ?
                <div className={`text-sm border rounded-md p-1 border-black w-full ${className} `}>
                    {findFromListCustom(valueField, value, options || [], labelField)}
                </div>
                :
                <Select key={value} searchBy={searchBy} options={options || []}
                    closeOnSelect
                    className={`${className} `}
                    disabled={readOnly}
                    required={required}
                    labelField={labelField}
                    valueField={valueField}
                    multi={false}
                    values={value ? [{
                        [valueField]: value,
                        [labelField]: findFromListCustom(valueField, value, options || [], labelField)
                    }] : []}
                    onChange={(value) => {
                        setValue(value[0] ? value[0][valueField] : "")
                    }} />
            }
        </div>
    )
}