import React from 'react'
import ProjectList from './ProjectList'
import 'semantic-ui-css/semantic.min.css'

const ProjectDetails = () => {
    return (
        <div>
            <ProjectList />
        </div>
    )
}

export default ProjectDetails
