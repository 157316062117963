import React from 'react'
import { TICK_ICON } from '../../../icons'

const MonthlyEmployee = ({ attendanceData, setAttendanceData }) => {
    function handleChange(index, field, value) {
        setAttendanceData(prev => {
            let newList = structuredClone(prev);
            if (field == 'shiftLength' && (newList[index][field] == value)) {
                newList[index][field] = '';
            } else {
                newList[index][field] = value;
            }
            return newList;
        })
    }
    return (
        <div
            className="min-h-[300px] overflow-auto"
        >
            <table className="table-fixed text-center w-full">
                <thead className="border-2 table-header">
                    <tr className='h-2'>
                        <th
                            className="border-2  top-0 stick-bg w-10"
                        >
                            S. no.
                        </th>
                        <th
                            className="border-2  top-0 stick-bg "
                        >
                            <div>Reg.No</div>
                        </th>
                        <th
                            className="border-2  top-0 stick-bg"
                        >
                            <div>Emp.Name</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Full Day</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Half Day</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Leave</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>Permission(Hours)</div>
                        </th>
                        <th

                            className="border-2  top-0 stick-bg "
                        >
                            <div>OT(Hours)</div>
                        </th>
                    </tr>
                </thead>
                <tbody className="border-2">
                    {(attendanceData).map((dataObj, index) => (
                        <tr
                            key={dataObj.id}

                            className="border-2 text-sm cursor-pointer"
                        >
                            <td className='py-1'> {(index + 1)}</td>
                            <td className='py-1'> {dataObj.regNo}</td>
                            <td className='py-1'>{dataObj.name}</td>
                            <td className='py-1' onClick={() => {
                                handleChange(index, 'shiftLength', 'full')
                            }}>
                                {(dataObj.shiftLength === 'full') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1' onClick={() => {
                                handleChange(index, 'shiftLength', 'half')
                            }}>
                                {(dataObj.shiftLength === 'half') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1' onClick={() => {
                                handleChange(index, 'shiftLength', 'leave')
                            }}>
                                {(dataObj.shiftLength === 'leave') && <>{TICK_ICON}</>}
                            </td>
                            <td className='py-1' >
                                <input type="number" min={0} className='text-right' value={dataObj.permissionHours} onChange={(e) => {
                                    handleChange(index, 'permissionHours', e.target.value)
                                }} />
                            </td>
                            <td className='py-1' >
                                <input type="number" min={0} className='text-right' value={dataObj.overTimeHours} onChange={(e) => {
                                    handleChange(index, 'overTimeHours', e.target.value)
                                }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MonthlyEmployee
